var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-detail" }, [
    _c("h1", { staticClass: "video-detail-title" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c("div", { staticClass: "video-detail-player" }, [
      _c("div", { ref: "videoPlayer", attrs: { id: "videoPlayer" } }),
    ]),
    _vm.description
      ? _c("div", {
          staticClass: "video-detail-description",
          domProps: { innerHTML: _vm._s(_vm.description) },
        })
      : _vm._e(),
    _vm.baristas && _vm.baristas.length > 0
      ? _c(
          "div",
          { staticClass: "video-detail-pro" },
          [
            _c("h2", { staticClass: "video-detail-pro-title" }, [
              _vm._v("この動画に出てきた" + _vm._s(_vm.WORDS.PRO)),
            ]),
            _c("ProCard", {
              attrs: { content: _vm.baristas },
              on: { "click-pro-card": _vm.onClickProCard },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }