<template>
  <div class="video-detail">
    <h1 class="video-detail-title">
      {{ title }}
    </h1>

    <div class="video-detail-player">
      <div ref="videoPlayer" id="videoPlayer" />
    </div>

    <div
      v-if="description"
      v-html="description"
      class="video-detail-description" />

    <div v-if="baristas && baristas.length > 0" class="video-detail-pro">
      <h2 class="video-detail-pro-title">この動画に出てきた{{ WORDS.PRO }}</h2>

      <ProCard @click-pro-card="onClickProCard" :content="baristas" />
    </div>
  </div>
</template>

<script>
import { ORIGIN } from '@/app.config';
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  computed,
  onBeforeUnmount
} from '@vue/composition-api';

export default defineComponent({
  setup: (props, context) => {
    /**
     * 再生ステータス
     * -1 – 未開始
     * 0 – 終了
     * 1 – 再生中
     * 2 – 一時停止
     * 3 – バッファリング中
     * 5 – 頭出し済み
     * @see https://developers.google.com/youtube/iframe_api_reference?hl=ja#Playback_status
     */
    const state = ref(-1);
    const videoPlayer = ref(null);
    const isDone = ref(false);
    const isFirstTime = ref(false);
    const isMounted = ref(false);
    const content = ref(undefined);
    const youtubePath = 'https://youtu.be/';
    const youtubeId = ref('');
    const contentId = ref(undefined);
    const title = computed(() => content.value?.title);
    const description = computed(() => content.value?.description);
    const baristas = computed(() => content.value?.baristas);

    // const plusMileage = ref(0);
    // const isStageUp = ref(false);
    // const nowMileage = ref(0);
    // const hasPresent = ref(false);
    // const stageNameBefore = ref('');
    // const stageNameAfter = ref('');
    // const stageNameNext = ref('');

    const stateLabel = computed(() => (state.value === 1 ? '再生中' : ''));

    const insertScriptTag = () => {
      const tag = document.createElement('script');
      const firstScriptTag = document.getElementsByTagName('script')[0];
      tag.src = 'https://www.youtube.com/iframe_api';
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    const init = async () => {
      videoPlayer.value = await new window.YT.Player('videoPlayer', {
        origin: ORIGIN,
        enablejsapi: 1,
        autoplay: 0,
        height: '360',
        width: '640',
        videoId: youtubeId.value,
        playsinline: 1,
        events: {
          onStateChange: onPlayerStateChange
        }
      });
    };

    const onPlayerStateChange = async (event) => {
      state.value = await event.data;
      // if (state.value === 1 && !isFirstTime.value) {
      //   isFirstTime.value = true;
      //   onPresentIncentive(content.value?.movie_url);
      // }
      if (state.value === window.YT.PlayerState.PLAYING && !isDone.value) {
        setTimeout(() => {
          isDone.value = true;
        }, 200);
      }
    };

    const fetchVideoDetail = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('video')
          .get(contentId.value);
        content.value = data;
        youtubeId.value = data?.movie_url.replace(youtubePath, '');

        window.onYouTubeIframeAPIReady = () => {
          init();
        };
        window.onYouTubeIframeAPIReady();
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    /**
     * インセンティブの付与時の処理
     * @param {string} contentUrl
     */
    // const onPresentIncentive = async (contentUrl) => {
    //   // eslint-disable-next-line no-console
    //   console.debug('onPresentIncentive');
    //   const hasAlreadyRead = !!contentUrl && contentUrl !== null;
    //   if (hasAlreadyRead) {
    //     await alreadyRead(contentUrl);
    //     context.root.$contentList.presentIncentive(
    //       plusMileage.value,
    //       isStageUp.value,
    //       nowMileage.value,
    //       hasPresent.value,
    //       stageNameBefore.value,
    //       stageNameAfter.value,
    //       stageNameNext.value,
    //       false
    //     );
    //   }
    // };

    /**
     * 既読処理
     * @param {string} contentUrl
     */
    // const alreadyRead = async (contentUrl) => {
    //   // eslint-disable-next-line no-console
    //   console.debug('alreadyRead');
    //   if (!contentUrl) return;
    //   try {
    //     context.root.$_loading_start();

    //     const { data } = await context.root
    //       .$repositories('contentAlreadyRead')
    //       .post({
    //         url: contentUrl
    //       });

    //     plusMileage.value = data?.mileage['plus_mileage'];
    //     isStageUp.value = data?.mileage['rank_up'];
    //     nowMileage.value = data?.mileage['now_mileage'];
    //     hasPresent.value = !!data?.present[0];
    //     stageNameBefore.value = data.mileage.stage?.before.name;
    //     stageNameAfter.value = data.mileage.stage?.after.name;
    //     stageNameNext.value = data.mileage.stage?.next.name;

    //     // ダイアログを表示するとき（ステージアップやプレゼントがあるとき）に動画を一時停止する
    //     if (data?.mileage['rank_up'] || !!data?.present[0]) stopVideo();
    //   } catch {
    //     // noop
    //   } finally {
    //     context.root.$_loading_stop();
    //   }
    // };

    const stopVideo = () => {
      videoPlayer.value.pauseVideo();
    };

    // 当該画面から別の画面を開く際に動画を一時停止する
    const onClickProCard = () => stopVideo();

    insertScriptTag();
    contentId.value = Number(context.root.$route.params.id);
    fetchVideoDetail();

    onBeforeUnmount(() => {
      stopVideo();
    });

    return {
      WORDS,
      title,
      description,
      baristas,
      youtubeId,
      contentId,
      content,
      videoPlayer,
      state,
      stateLabel,
      isFirstTime,
      isDone,
      isMounted,

      onClickProCard
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/mixin';

.video-detail {
  padding-bottom: 16px;
}

.video-detail-title {
  margin-bottom: 16px;
  padding: 0 16px;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
}

.video-detail-description {
  margin-bottom: 0;
  padding: 24px 16px;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
}

.video-detail-player::v-deep {
  @include mixin.imageAspectRatio(343, 193);

  width: 100%;
  line-height: 1;

  iframe {
    width: 100%;
    height: 100%;
    object-fit: unset;
  }
}

.video-detail-pro {
  padding: 0 16px;
}

.video-detail-pro-title {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: bold;
}
</style>
